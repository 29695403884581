<template>
    <div class="loader">
        <div :style="[{ height: `${loaderHeight}` },]"></div>
        <div :style="[{height: `${loaderHeight}`}]"></div>
        <div :style="[{height: `${loaderHeight}`}]"></div>
        <!-- <div>UPLOADING.....</div> -->
    </div>
</template>

<script>
export default {
    name: 'WaveLoader',
    props: ['loaderHeight']
}
</script>

<style lang="scss" scoped>
.loader {
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader div {
  background: #fff;
  width: 16px;
//   height: 2.5rem;
  margin-left: 10px;
  animation: loader 1.2s infinite;
}

@keyframes loader {
  50% {
    height: 15px;
  }
}

.loader div:nth-child(1) {
  background: #ff3366;
  animation-delay: -0.4s;
}

.loader div:nth-child(2) {
  background: #ed6486;
  animation-delay: -0.2s;
}

.loader div:nth-child(3) {
  background: #f3b8c7;
  animation-delay: 0s;
}
</style>